import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from '../../../../RoutesWithAuth';
import axios from '../../../common/AxiosConfig';
import DataGrid from '../grid';
import { makeStyles } from "@material-ui/core/styles";
import { DATEFORMATLOCAL, DATEFORMATUTC, RequestorMapping,RequestTypeMapping } from '../../../common/commons';
import RowMenuCell from '../grid/RowEditActions';
import { LoadingIndicator } from '../reports/reconcilationreport/MuiStyled';
import moment from 'moment';
import { Tooltip } from '@material-ui/core';
import RequestTypeWrapper from './RequestTypeWrapper';


const Requestor = [RequestorMapping.B2B, RequestorMapping.CUSTOMER, RequestorMapping.EMPLOYEE, RequestorMapping.TCICUSTOMER, "Survivor"];
const COLUMNS = [{
    name: "#",
    mapping: "rowId",
    width: 40
}, {
    name: 'Request Type',
    mapping: "request_type",
    renderCell: (params) => (
        <>
            {RequestTypeMapping[params.row["request_type"]]}
        </>
    ),
}, {
    name: 'Threshold Days',
    mapping: "threshold_time",
    editable: true,
    width: 160,
    type: 'singleSelect',
    valueOptions: ['3', '4', '5' , '6' , '7' , '8' , '9' , '10' , '11' , '12'],
}, {
    name: 'Updated By',
    mapping: "updated_by",
}, {
    name: 'Updated On',
    mapping: "updated_on",
    width: 200,
    renderCell: (params) => (

        <Tooltip title={params.row["updateDateLocal"]}>
            <span>
                {params.row["updatedDateUTC"]}
            </span>
        </Tooltip>
    ),
}, {
    name: "Edit",
    mapping: "actions",
    adminEnabled: true,
    width: 80,
    editable: false,
    renderCell: RowMenuCell
}];

const SURVIVOR_COLUMNS = [{
    name: "#",
    mapping: "rowId",
    width: 40
}, {
    name: 'Request Type',
    mapping: "request_type",
    renderCell: (params) => (
        <>
            {RequestTypeMapping[params.row["request_type"]]}
        </>
    ),
}, {
    name: 'Threshold Hours',
    mapping: "threshold_time",
    editable: true,
    width: 160,
    type: 'singleSelect',
    valueOptions: Array.from({length:23},(_,i)=>(i+2).toString()),
}, {
    name: 'Updated By',
    mapping: "updated_by",
}, {
    name: 'Updated On',
    mapping: "updated_on",
    width: 200,
    renderCell: (params) => (

        <Tooltip title={params.row["updateDateLocal"]}>
            <span>
                {params.row["updatedDateUTC"]}
            </span>
        </Tooltip>
    ),
}, {
    name: "Edit",
    mapping: "actions",
    adminEnabled: true,
    width: 80,
    editable: false,
    renderCell: RowMenuCell
}];

const useStyles = makeStyles(() => ({
    appscopeWrapper: {
        marginTop: '4px',
        marginRight: '15px'
    },
    noteWrapper: {
        marginTop: '10px',
        fontStyle: 'italic'
    }
}));
function EscalationSettings({ activeTab, fromredirection, workflow }) {

    const [appsData, setAppsData] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);
    
    const requestor = Requestor[activeTab];
    const context = useContext(AppContext)
    const authToken = context.authToken.get
    const classes = useStyles()
    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authToken, activeTab, fromredirection])
    
    const fetchData = () => {
        setIsProcessing(true);
        const params = {
            requestor
        }
        axios.get(`settings/escalation`, {
            headers: {
                Authorization: authToken
            },
            params
        }).then(res => {
            let appsData = res.data;
            setAppsData(appsData);
            setIsProcessing(false);

        }).catch(err => {
            setIsProcessing(false);
            console.log(err)
        })
    }
    const updateApp = (rowInfo) => {
        setIsProcessing(true);
        const payload = {
            requestor,
            request_type : rowInfo.request_type,
            threshold_time : rowInfo.threshold_time
        };
        
        axios.put(`settings/escalation`, payload , {
            headers: {
                Authorization: authToken
            }
        })
            .then(res => {
                fetchData();
                setIsProcessing(false);
            }).catch(err => {
                setIsProcessing(false);
                console.log(err)
            })
    }
    const dataGridColumns = () => {
        if(requestor === "Survivor" ){
        return SURVIVOR_COLUMNS
        }
        return COLUMNS;
    }
    const appsGridData = () => {
        return appsData?.map((row, i) => {
            return {
                rowId: (i + 1),
                originalId: row.id,
                id: `${(i + 1)}-${activeTab}`,
                ...row,
                updateDateLocal: row.updated_on ? moment(row.updated_on).local().format(DATEFORMATLOCAL) : '--',
                updatedDateUTC: row.updated_on ? moment(row.updated_on).utc().format(DATEFORMATUTC) : '--',
            }
        })
    }
    return (
        <div className={classes.appscopeWrapper} key={`EscalationSettings-${activeTab}`}>
            {isProcessing && (<LoadingIndicator />)}
            <DataGrid
                disableSelectionOnClick={false}
                columns={dataGridColumns()}
                rows={appsGridData()}
                onRowEditCommit={(rows, event) => {
                    if (event.rowInfo) {
                        updateApp(event.rowInfo);
                    }
                }}
            />
            <div className={classes.noteWrapper}>
                Note: CPS automatically sends an escalation email to downstream POCs if the response is pending for more than 'threshold {requestor === "Survivor" ?"hours":"days"}'.
            </div>
        </div>
    )
}
export default React.memo(EscalationSettings);

const EscalationSettingsWrapper = (parentprops) => {
    return (<RequestTypeWrapper
        additionalTab={[{
            name: "survivor",
            display: "survivor"
        }]}
        title={"ESCALATION SETTINGS"}
        routePath={"escalationsetting"}
        renderTabContent={(props) => {
            return <EscalationSettings
                {...props} />
        }}
        {...parentprops}
    />)
}
export const EscalationSettingsTab = React.memo(EscalationSettingsWrapper);