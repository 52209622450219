import { Snackbar, Tooltip, Typography, makeStyles, Toolbar } from '@material-ui/core';
import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from '../../../../common/AxiosConfig';
import { AppContext } from '../../../../../RoutesWithAuth';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { DATEFORMATLOCAL, DATEFORMATUTC, RequestorMapping, v2UIRoutePath } from '../../../../common/commons';
import FormGroup from "../../FormGroup";
import { LoadingIndicator, Tab, Tabs } from '../reconcilationreport/MuiStyled';
import { Link, useLocation } from 'react-router-dom';
import DataGrid from '../../grid';
import Pagination from '../../grid/Pagination';
import Papa from 'papaparse';
import { Alert } from '@material-ui/lab';


const DELETEDCOLUMNS = [{
    name: "#",
    mapping: "rowId",
    disableColumnMenu: true,
    width: 55
}, {
    name: 'Request ID',
    mapping: "DSAR",
    width: 400,
    disableColumnMenu: true
}, {
    name: 'Request Type',
    mapping: "Request Type",
    width: 150,
    disableColumnMenu: true
}, {
    name: 'Requestor',
    mapping: "Requestor",
    width: 95,
    disableColumnMenu: true
}, {
    name: "Request Status",
    mapping: "Request Status",
    width: 120,
    disableColumnMenu: true
}, {
    name: "Created Date",
    mapping: "Created Date",
    width: 180,
    renderCell: (params) => (
        <Tooltip title={params.row["createdDateandTimeLocal"]}>
            <span>
                {params.row["createdDateandTimeUTC"]}
            </span>
        </Tooltip>
    ),
    disableColumnMenu: true
}, {
    name: 'Deleted Date',
    mapping: "Deleted Date",
    width: 180,
    renderCell: (params) => (
        <Tooltip title={params.row["deleteddateLocal"]}>
            <span>
                {params.row["deleteddateUTC"]}
            </span>
        </Tooltip>
    ),
    disableColumnMenu: true
}, {
    name: 'Deletion Reason',
    mapping: 'Deletion Reason',
    width: 160,
    disableColumnMenu: true,
    displayTootip: ["Deletion Reason"],
}, {
    name: 'Deleted By',
    mapping: 'Deleted By',
    width: 160,
    disableColumnMenu: true
}]
// Columns Specific to Cancellation Tab
export const COLUMNS = [{
    name: "#",
    mapping: "rowId",
    disableColumnMenu: true,
    width: 55
}, {
    name: 'Request ID',
    mapping: "DSAR",
    width: 400,
    disableColumnMenu: true,
    renderCell: (params) => {
        return (<span className='ashyperlink'>
            {params.row["DSAR"]}
        </span>)
    }
}, {
    name: 'Request Type',
    mapping: "Request Type",
    width: 150,
    disableColumnMenu: true
}, {
    name: 'Requestor',
    mapping: "Requestor",
    width: 110,
    disableColumnMenu: true
}, {
    name: "Created Date",
    mapping: "Created Date",
    width: 200,
    renderCell: (params) => (
        <Tooltip title={params.row["createdDateandTimeLocal"]}>
            <span>
                {params.row["createdDateandTimeUTC"]}
            </span>
        </Tooltip>
    ),
    disableColumnMenu: true
}, {
    name: 'Cancelled Date',
    mapping: "cancellation_time",
    width: 200,
    renderCell: (params) => (
        <Tooltip title={params.row["cancellationTimeLocal"]}>
            <span>
                {params.row["cancellationTimeUTC"]}
            </span>
        </Tooltip>
    ),
    disableColumnMenu: true
}, {
    name: 'Cancellation Reason',
    mapping: 'Cancellation Reason',
    width: 180,
    disableColumnMenu: true,
    displayTootip: ["Cancellation Reason"],
}, {
    name: 'Cancelled By',
    mapping: 'Cancelled By',
    width: 160,
    disableColumnMenu: true
}];
const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1',
        height: '100%'
    },
    erroralert: {
        float: 'right',
        color: 'red',
        marginLeft: '15px',
        marginBottom: '10px',
        marginTop: '-8px',
    },
    accordianTitle: {
        fontWeight: 'bold'
    },
    formGroupWrapper: {
        width: '600px',
        marginTop: '10px',
        '& .multiSelectedOptions': {
            width: '140px'
        }
    },
    customInputCls: {
        width: '140px',
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        "& .selectStyle": {
            padding: '0.5px',
            height: '27px',
            marginTop: '4px',
            fontSize: '0.8rem'
        },
        "& .iconadvSearch svg": {
            fontSize: '20px',
        }
    },

}));
const requestTabs = ["", "deletedrequests"];

const CancelledOrDeletedRequestReport = ({ tabActiveParam }) => {
    const classes = useStyles();
    // State to handle the Main Tab Information
    const [activeTab, setActiveTab] = useState(0);
    const [cancelledRequests, setCancelledRequests] = useState([]);
    const [activeFilters, setActiveFilters] = useState({});
    const [error, setError] = useState('');
    const [pageCount, setPageCount] = useState(20);
    const [totalResults, setTotalResults] = useState(0);

    //state for maintaining form related data
    const [formData, setFormData] = useState({
        from_date: "",
        to_date: "",
        requestor: ['All']
    });
    const history = useHistory();
    const location = useLocation();

    const formGroupRef = useRef();
    const today = moment();

    const [currentPage, setCurrentPage] = useState(1);
    const [isInProgress, setIsInProgress] = useState(false);
    const [displayErrMsg, setDisplayErrMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");

    const context = useContext(AppContext)
    const authToken = context.authToken.get

    const handleClose = () => {
        setDisplayErrMsg("");
        setSuccessMsg("");
    }

    useEffect(() => {
        // Activating the Tab based on the tab param in the route url
        let activeTabIndex = requestTabs.indexOf(tabActiveParam);
        // activating the first tab when the tab name does not match the list
        if (activeTabIndex < 0) {
            activeTabIndex = 0;
        }
        setActiveTab(activeTabIndex);
        // resetting the Form when there is change in the active tab
        formGroupRef.current.resetFormToDefaults();
        setFormData({
            from_date: "",
            to_date: "",
            requestor: ['All']
        })
        setPageCount(20)
        setError("");
    }, [tabActiveParam])

    useEffect(() => {
        // resetting the Form when there is change in the Location state.
        formGroupRef.current.resetFormToDefaults();
        setFormData({
            from_date: "",
            to_date: "",
            requestor: ['All']
        })
        setPageCount(20)
        setError("");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location?.state?.fromredirection])
 useEffect(()=>{
        setCurrentPage(1);
    },[pageCount]);
    useEffect(() => {
        if (authToken) {
            fetchCancelledRequestsData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData, currentPage, pageCount]);
    /**
     * This Method is used to handle the Download Response and display the message accordingly based on the response
     * @param {*} res 
     */
    const handleDownload = (res) => {
        let downloadText = '/CANCELLATION_REPORT/';
        if (activeTab === 1) {
            downloadText = '/DELETION_REPORT/';
        }
        if (res.status === 204) {
            // Display the Error Snackbar
            setDisplayErrMsg("No records to download")
        } else if (res.data.email_sent === "1") {
            // Display the success Message when email is sent
            setSuccessMsg(res.data.message)
        } else if (res.data.includes(downloadText)) {
            // creating the downloadable link when link is passed from the backend
            const link = document.createElement('a');
            link.href = res.data;
            document.body.appendChild(link);
            link.click();
            link.remove()
        }
        else {
            // Don't update the state if download_csv is set to true
            // Download the csv file
            const csv = Papa.unparse(res.data)
            createBlobAndDownload(csv)
        }
    }
    const createBlobAndDownload = (csv) => {
        // Forming the file name based on the active Tab
        let fileName = 'Cancellation_Report';
        if (activeTab === 1) {
            fileName = 'Deletion_Report';
        }
        // generating the downloadable Link based on the response data
        const downloadLink = document.createElement("a");
        const blob = new Blob(["\ufeff", csv])
        const url = URL.createObjectURL(blob)
        downloadLink.href = url
        const timestamp = moment().format("YYYYMMDDHHmmss")
        downloadLink.download = `CPS_${fileName}_${timestamp}.csv`
        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)
    }
    const fetchCancelledRequestsData = (download_flag = false) => {
        let requestor = formData.requestor.join(', ')?.toLowerCase();
        if (requestor === "all") {
            requestor = "ALL"
        } else if (requestor === "") {
            return false
        }
        setIsInProgress(true)
        // Formating the API Url Based on the active Tab
        let apiUrl = '/reports/cancellation';
        if (activeTab === 1) {
            apiUrl = '/reports/deletion-report';
        }
        axios.get(apiUrl, {
            params: {
                ...formData,
                requestor,
                page: (currentPage - 1),
                limit: pageCount,
                download_csv: download_flag
            },
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            // handling the Response separately for the Download
            if (download_flag) {
                handleDownload(res)
            } else {
                let responseData = res?.data?.data;
                let total_results = res?.data?.total_results;
                if (res.status === 204) {
                    responseData = [];
                    total_results=0;
                }
                setCancelledRequests(responseData)
                setTotalResults(total_results);
            }
            setIsInProgress(false)
        }).catch(err => {
            setIsInProgress(false)
        })
    }
    const handleDownloadGrid = () => {
        fetchCancelledRequestsData(true);
    }
    const handlePageChange = (page) => {
        setCurrentPage(page);
    }
    const gridData = cancelledRequests?.map((_gridData, index) => {
        // Formating the Grid Data to Handle the Local and the UTC TIME
        return {
            ..._gridData,
            rowId: ((currentPage - 1) * pageCount) + (index + 1),
            id: ((currentPage - 1) * pageCount) + (index + 1),
            createdDateandTimeLocal: _gridData["Created Date"] ? moment(_gridData["Created Date"]).local().format(DATEFORMATLOCAL) : '--',
            createdDateandTimeUTC: _gridData["Created Date"] ? moment(_gridData["Created Date"]).utc().format(DATEFORMATUTC) : '--',
            cancellationTimeLocal: _gridData["Cancelled Date"] ? moment(_gridData["Cancelled Date"]).local().format(DATEFORMATLOCAL) : '--',
            cancellationTimeUTC: _gridData["Cancelled Date"] ? moment(_gridData["Cancelled Date"]).utc().format(DATEFORMATUTC) : '--',
            deleteddateLocal: _gridData["Deleted Date"] ? moment(_gridData["Deleted Date"]).local().format(DATEFORMATLOCAL) : '--',
            deleteddateUTC: _gridData["Deleted Date"] ? moment(_gridData["Deleted Date"]).utc().format(DATEFORMATUTC) : '--',

        }
    })
  
    return (
        <div className={classes.root}>
            {isInProgress && (<>
                <LoadingIndicator />
            </>)}
            <Snackbar open={!!displayErrMsg} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    {displayErrMsg}
                </Alert>
            </Snackbar>
            <Snackbar open={!!successMsg} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    {successMsg}
                </Alert>
            </Snackbar>
            <Typography variant="h6" className={'tabHeader'}>
                <Link color="primary" to={() => {
                    // Generating a Random Id and passing it as part of state 
                    // Every click on the Header will generate a unique Id
                    const autoId = Math.round(Math.random() * 1e5);
                    let subTab = "";
                    // Maintaining the active Tab State.
                    if (activeTab === 1) {
                        subTab = "deletedrequests"
                    }
                    return {
                        pathname: v2UIRoutePath + "reports/cancelleddeletedrequests/" + subTab,
                        state: { fromredirection: autoId }
                    }
                }}
                >
                    CANCELLED AND DELETED REQUESTS
                </Link>
            </Typography>
            <Tabs
                variant="scrollable"
                aria-label="tabs"
                value={activeTab}
                onChange={(_event, newValue) => {
                    setActiveTab(newValue);
                    // redirecting to the selected tab url so that refresh will retain in same page
                    history.push(`${v2UIRoutePath}reports/cancelleddeletedrequests/${requestTabs[newValue]}`)

                }}
                sx={{ borderRight: 1, borderColor: 'divider' }}
            >
                <Tab label="Cancelled Requests" />
                <Tab label="Deleted Requests" />
            </Tabs>
            <Toolbar className={classes.toolbar}>
            <FormGroup
                formWrapperClassName={classes.formGroupWrapper}
                ref={formGroupRef}
                fields={[{
                    type: 'date',
                    name: "from_date",
                    label: "From Date",
                    defaultOption: "",
                    inputAdditionalProps: {
                        min: "2020-01-01",
                        max: today.format("YYYY-MM-DD")
                    }
                }, {
                    type: 'date',
                    name: "to_date",
                    label: "To Date",
                    defaultOption: "",
                    inputAdditionalProps: {
                        min: formData?.from_date || "2020-01-01",
                        max: today.format("YYYY-MM-DD")
                    }
                }, {
                    type: 'multiselect',
                    name: 'requestor',
                    label: 'Requestor',
                    isMulti: true,
                    customInputCls: classes.customInputCls,
                    options: ["All", ...Object.values(RequestorMapping)],
                    defaultOption: 'All',
                    size: 2
                }]}
                disableFormActions={true}
                customFormUpdates={(_formData) => {
                    // resetting the To_Date when there is a change in the from date
                    // if(formData.from_date !== _formData.from_date ){
                    //     _formData.to_date = "";
                    // }
                    if (_formData.from_date && _formData.to_date && moment(_formData.to_date) < moment(_formData.from_date)) {
                        setError("'From Date' cannot be Future Date than 'To Date'")
                    } else {
                        setError("");
                    }
                    // This Method is called when ever there is any change in the form fields
                    setFormData(_formData)
                    // resetting to Initial Page since filtering is handling at backend
                    setCurrentPage(1);
                }}
            />
          
           
                  <div className="row mt-4">
                  
                    <label htmlFor="pagecount" className="col-form-label">Records per Page :</label>
                    <div className="mr-4">
                        <select onChange={(event) => { setPageCount(event.target.value); }} className="selectStyle form-control ml-2" value={pageCount} id="pagecount" data-testid="pagecount-field">
                            <option value="20">20</option> 
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                </div>
                </Toolbar>
                {error !== "" && (<div className={classes.erroralert}>
                {error}
            </div>)}
            <DataGrid
                columns={activeTab === 0 ? COLUMNS : DELETEDCOLUMNS}
                enableCustomFiltering={true}
                rows={gridData || []}
                defaultFilters={activeFilters}
                onCellClick={activeTab === 0  ? (cellparams) => {
                    history.push(v2UIRoutePath + "requestdetails/" + cellparams.row["DSAR"]);
                } : ()=>{}}
                applyRemoteFiltering={(filters) => {
                    setActiveFilters(filters)
                }}
                remoteFiltering={true}
                toolbarConfig={{
                    downloadLabel: "Download",
                    handleDownloadGrid
                }}
            />
        <Pagination
                currentPage={currentPage}
                pageCount={pageCount}
                totalResults={totalResults}
                pageChange={handlePageChange}
            />
        </div>
    )
};

export default CancelledOrDeletedRequestReport;