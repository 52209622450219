import { FormControl, Grid, Select, MenuItem } from '@material-ui/core';
import { AppContext } from '../../../RoutesWithAuth';
import React, { useState, useEffect,useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import axios from '../../common/AxiosConfig';
import {RequestorMapping } from '../../common/commons';


const useStyles = makeStyles({
    root: {
        '& .ml-1':{
            fontWeight : 'bold',
            width : '100%'
        },
      '& .MuiInputBase-input':{
        paddingTop : '5px',
        paddingBottom : '5px',
        fontSize: '0.8rem',
        paddingLeft: '10px'
      },
      '& .MuiListItem-root':{
        fontSize: '0.8rem',
        padding: '0px'
      }
    },
    menuitemmultiselect:{
        fontSize: '0.8rem',
        padding: '2px',
        paddingLeft: '10px',
        '& .Mui-checked' : {
            color : '#7f9ed7'
        }
    },
    requestsfieldWrapper :{
        //display: 'inline-block',
       // float: 'left',
        flex:1,
        paddingLeft: '15px',
        paddingRight: '15px',
    },
    requestsfieldHeader :{
        fontWeight:'bold',
        paddingBottom: '10px',
    }
  });


function RequestsCount() {
    
    const classes = useStyles();
    const [requestHealth, setRequestHealth] = useState();
    const [requestor , setRequestor] = useState("All");
    const context = useContext(AppContext)
	const authToken = context.authToken.get

    useEffect(() => {
        axios.get("reports/request_health", {
                headers: {
                    Authorization: authToken
                },
                params:{
                    requestor
                }
            }).then(res => {
                setRequestHealth(res.data)
            })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestor,authToken])

    const updateFormData = (value)=>{
        setRequestor(value);
    }
    const requestorCountFields= [
        { name:"Tot. Request Count", field: 'total_count'},
        { name:"Pending RTC", field: 'rtc'},
        { name:"Pending RTCR", field: 'rtcr'},
        { name:"Pending RTD", field: 'rtd'},
        { name:"Pending RTK", field: 'rtk'},
        { name:"Pending RTO", field: 'rtoo'},
        { name:"Pending RTT", field: 'rtt'},
    ];
    const requestorList = ["All",RequestorMapping.B2B, RequestorMapping.CUSTOMER,RequestorMapping.EMPLOYEE,RequestorMapping.SURVIVOR,RequestorMapping.TCICUSTOMER]
    return (
        <Grid container className={classes.root}>
                <Grid item xs={2} >
                    <div className="form-group col">
                        <label for={"requestor"} className="ml-1">{"Requestor"}</label>
                        <FormControl fullWidth variant="outlined" >
                            <Select
                                size={"small"}
                                id={"requestor"}
                                multiple={false}
                                onChange={(event)=>{updateFormData(event.target.value)}}
                                value={requestor}
                                renderValue={(selected) => selected}
                                > 
                                {requestorList.map((name) => (
                                    <MenuItem key={name} className={`${classes.menuitemmultiselect}`} value={name}>
                                       {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </Grid>
                <Grid item xs={12} style={{display:'flex'}}>
                    {requestorCountFields.map(({name,field})=>{
                        return (
                            <div key={name} className={classes.requestsfieldWrapper}>
                                <div className={classes.requestsfieldHeader}>{name}</div>
                                <div data-testid={`value-${field}`}>{requestHealth?.[field] || '--'}</div>
                            </div>  
                        )
                    })}
                               
                </Grid>
        </Grid>
    )
}
export default RequestsCount;